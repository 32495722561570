import parseDates from '@/data/parseDates';
import { getAuthToken } from '@/firebase/client';
import { GRAPHQL_URL } from '@/pages/api/urls';
import type { Mutation, Query } from '@/types/schema';
import { getSessionCompanyId } from '@/utils/getCompanyId';
import { ApolloClient, HttpLink, InMemoryCache, OperationVariables, QueryOptions } from '@apollo/client';
import type { MutationOptions } from '@apollo/client/core/watchQueryOptions';
import { setContext } from '@apollo/client/link/context';

const authLink = setContext( async ( { operationName }, { headers, ...rest } ) => {
	const sessionCompanyId = getSessionCompanyId() || '';
	const idToken = await getAuthToken();
	const isServer = typeof window === 'undefined';
	return {
		uri    : `${GRAPHQL_URL}?${operationName}`,
		headers: isServer
			? { authorization: process.env.SECRET, ...headers }
			: { authorization: idToken, company: sessionCompanyId, ...headers },
		...rest,
		...( isServer || operationName === 'UserRead' ) && { fetchOptions: { cache: 'no-cache' } },
	};
} );

const httpLink = new HttpLink( { uri: GRAPHQL_URL } );

export const apolloClient = new ApolloClient( {
	link          : authLink.concat( httpLink ),
	ssrMode       : typeof window === 'undefined',
	cache         : new InMemoryCache( { addTypename: false } ),
	defaultOptions: { query: { fetchPolicy: 'no-cache' } },
} );

function errorHandling( e: Error ): any {
	if ( typeof window === 'undefined' ) throw e;
	
	if ( e.message === 'Failed to fetch' ) return; // Do nothing as it's due to cancelled requests
	
	// UI error handling
	if ( e.message.startsWith( 'UI:' ) ) throw new Error( e.message.replace( 'UI:', '' ) );
	// throw e;
	throw new Error( 'Something went wrong! Please try again or refresh your page' );
}

export async function queryGraphQL<T extends OperationVariables, R = Query>( props: QueryOptions<T, R> ): Promise<R> {
	return apolloClient.query<R>( { ...props, fetchPolicy: 'no-cache' } )
		.then( ( { data } ) => parseDates( data ) )
		.catch( errorHandling );
}

export async function mutateGraphQL<T extends OperationVariables, R = Mutation>( props: MutationOptions<R, T> ) {
	return apolloClient.mutate<R, T>( props )
		.then( ( { data } ) => parseDates( data ) )
		.catch( errorHandling );
}
