import Loading from '@/components/loading';
import { EventEmitter } from 'events';
import { nanoid } from 'nanoid';
import dynamic from 'next/dynamic';
import { type ComponentType, createContext, Suspense, useContext, useRef, useState } from 'react';
import type { ResponsiveModalProps } from './responsiveModal';

const ResponsiveModal = dynamic( () => import( './responsiveModal' ), { suspense: true } );

type ModalInfo = {
	id: string,
	open: boolean,
	Component: ComponentType,
	modalProps?: Partial<ResponsiveModalProps>,
	props?: any
};

type C = {
	showModal: <T>(
		Component?: ComponentType<T>,
		modalProps?: Partial<ResponsiveModalProps>,
		props?: T,
	) => string,
	closeModal: ( id?: string ) => void,
	modalInfo: ( id: string ) => Promise<ModalInfo>,
	isOpen: boolean // Exposing open state
};

const ModalContext = createContext<C>( {
	showModal : () => null,
	closeModal: () => null,
	modalInfo : null,
	isOpen    : false, // Initialize with false
} );
ModalContext.displayName = 'Modal';

export type ModalControls = {
	closeModal: ( ...args ) => void,
	events: EventEmitter
};

const ModalControlsContext = createContext<ModalControls & { modalInfo: ModalInfo }>( {
	closeModal: () => null,
	modalInfo : null,
	events    : null,
} );
ModalControlsContext.displayName = 'ModalControls';

export default function ModalProvider( { children } ) {
	const [ modals, setModals ] = useState<ModalInfo[]>( [] );
	const modalsRef = useRef<ModalInfo[]>( modals );
	modalsRef.current = modals;
	
	function controls( id: string ): ModalControls {
		return {
			closeModal: ( ...args ) => setModals( ( modals ) => {
				const index = modals.findIndex( ( modal ) => modal?.id === id );
				if ( index === -1 ) return modals;
				const newModals = [ ...modals ];
				newModals[ index ] = { ...newModals[ index ], open: false };
				newModals[ index ]?.props.controls.events.emit( 'close', ...args );
				setTimeout( () => {
					setModals( ( modals ) => modals.filter( ( modal ) => modal?.id !== id ) );
				}, 500 );
				return newModals;
			} ),
			events    : new EventEmitter(),
		};
	}
	
	return (
		<ModalContext.Provider
			value={{
				showModal : ( Component, { id = nanoid(), ...modalProps } = {}, props ) => {
					setModals( ( modals ) => {
						const index = modals.findIndex( ( modal ) => modal?.id === id );
						const newModals = [ ...modals ];
						if ( index === -1 ) {
							newModals.push( {
								id,
								open : false,
								Component,
								modalProps,
								props: { ...props, controls: controls( id ) },
							} );
						} else {
							// found modal with same id
							newModals[ index ] = {
								...newModals[ index ],
								props: { ...newModals[ index ]?.props, ...props },
							};
							return newModals;
						}
						setTimeout( () => setModals( ( modals ) => {
							const index = modals.findIndex( ( modal ) => modal?.id === id );
							if ( index === -1 ) return modals;
							const newModals = [ ...modals ];
							newModals[ index ].props.controls.events.emit( 'open' );
							newModals[ index ] = { ...newModals[ index ], open: true };
							return newModals;
						} ), 0 );
						return newModals;
					} );
					return id;
				},
				closeModal: ( id ) => {
					if ( !id ) {
						modalsRef.current.forEach( ( modal ) => modal?.props.controls.closeModal() );
						return;
					}
					const modal = modalsRef.current.find( ( modal ) => modal?.id === id );
					modal?.props.controls.closeModal();
				},
				modalInfo : async ( id ) => await new Promise( ( resolve ) => setModals( ( modals ) => {
					const modal = modals.find( ( modal ) => modal?.id === id );
					resolve( modal );
					return modals;
				} ) ),
				isOpen    : modals.some( ( modal ) => modal.open ), // Check if any modal is open
			}}>
			{children}
			{modals.map( ( modal ) => {
				if ( !modal?.id ) return null;
				return (
					<ModalControlsContext.Provider key={modal.id} value={{ ...modal.props.controls, modalInfo: modal }}>
						<Suspense fallback={<Loading/>}>
							<ResponsiveModal
								open={modal.open}
								{...modal.modalProps}
								onClose={async ( event, reason ) => {
									try {
										modal.modalProps?.onClose?.( event, reason );
										modal.props.controls.closeModal();
									} catch ( e ) {
										return;
									}
								}}>
								<modal.Component {...modal.props}/>
							</ResponsiveModal>
						</Suspense>
					</ModalControlsContext.Provider>
				);
			} )}
		</ModalContext.Provider>
	);
}

export function useModal(): C {
	return useContext( ModalContext );
}

export function withModal( Component ) {
	return ( props ) => (
		<ModalContext.Consumer>
			{( modal ) => <Component modal={modal} {...props}/>}
		</ModalContext.Consumer>
	);
}

export function useModalControls() {
	return useContext( ModalControlsContext );
}
