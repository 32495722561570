import { isObject, keyBy, mapValues, stubTrue } from 'lodash-es';

export default function parseDates<T>( data: T ) {
	return deepMapDates( data, false ) as T;
}

const dateKeysMap = mapValues<string>( keyBy( [
	'updatedAt',
	'createdAt',
	'deletedAt',
	'standingDate',
	'dueDate',
	'dateSent',
	'endDate',
	'expDate',
	'serviceDate',
	'notified',
	'lastLogin',
	'end',
	'standingData',
] ), stubTrue );

const dateRegExp = new RegExp( /\d{4}(-\d{2}){2}/ );

function deepMapDates( obj: Record<string, any>, check: boolean ) {
	if ( typeof obj !== 'object' ) return obj;
	
	for ( const key of Object.keys( obj ) ) {
		let c = check;
		if ( dateKeysMap[ key ] ) c = true;
		const val = obj[ key ];
		if ( isObject( val ) ) {
			deepMapDates( val, c );
		} else if ( c && typeof val === 'string' && dateRegExp.test( val ) ) {
			obj[ key ] = new Date( val );
		}
	}
	return obj;
}
