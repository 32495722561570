export const getSessionStorage = <T>( key: string, select?: ( x: string ) => T ) => {
	if ( typeof window === 'undefined' || !( 'sessionStorage' in window ) ) {
		return null;
	}
	const value = sessionStorage.getItem( key );
	return select ? select( value ) : value;
};

export const getLocalStorage = ( key: string ): string | null => {
	if ( typeof window === 'undefined' || !( 'localStorage' in window ) ) {
		return null;
	}
	return localStorage.getItem( key );
};
