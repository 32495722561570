import type { BoxProps, CircularProgressProps } from '@mui/material';
import { Box, CircularProgress } from '@mui/material';
import type { ReactNode } from 'react';
import { Fragment, useEffect, useState } from 'react';

export default function Loading( { delay = 250, component, containerProps, ...props }: {
	delay?: number,
	component?: ReactNode,
	containerProps?: BoxProps
} & CircularProgressProps ) {
	const [ loading, setLoading ] = useState( false );
	
	useEffect( () => {
		const timeout = setTimeout( () => setLoading( true ), delay );
		return () => clearTimeout( timeout );
	}, [] );
	
	if ( !loading ) return null;
	
	if ( component ) return <Fragment>{component}</Fragment>;
	
	return (
		<Box
			textAlign='center'
			{...containerProps}
			sx={{
				p             : 2,
				marginTop     : 2,
				pt            : 'env(safe-area-inset-top)',
				height        : '100%',
				width         : '100%',
				alignItems    : 'center',
				justifyContent: 'center',
				...containerProps?.sx,
			}}>
			<CircularProgress {...props}/>
		</Box>
	);
}
