import { getApps, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const apps = getApps();

const app = process.env.NODE_ENV === 'development' && apps.length // hot reload error
	? apps[ 0 ]
	: initializeApp( {
		apiKey       : process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
		authDomain   : process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
		projectId    : process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
		storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
	} );

export const auth = getAuth( app );

export const getAuthToken = async (): Promise<string> => {
	const user = auth.currentUser;
	if ( !user ) return Promise.resolve( '' );
	try {
		return user.getIdToken();
	} catch ( error ) {
		console.error( 'Error retrieving ID token:', error );
		return '';
	}
};

export default app;
